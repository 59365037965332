import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';

import { whyOutsourceJavaData } from './payload';

import * as styles from './why-outsource-java.module.scss';

export const WhyOutsourceJava = () => {
  const { title, descriptionBelowTitle, javaUses, rightColumnDescription, backgroundImage, backgroundImageAlt } =
    whyOutsourceJavaData;
  return (
    <NewStandardSection
      classNames={{ innerWrapper: styles.innerWrapper, outerWrapper: styles.outerWrapper }}
      id="use-cases"
    >
      <div className={styles.leftColumn}>
        <div className={styles.titleAndDescriptionContainer}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{descriptionBelowTitle}</p>
        </div>
        <div className={styles.usagesContainer}>
          {javaUses.map((usage) => (
            <p key={usage}>{usage}</p>
          ))}
        </div>
      </div>
      <div className={styles.rightColumn}>
        <img loading="lazy" className={styles.backgroundImage} src={backgroundImage} alt={backgroundImageAlt} />
        <div className={styles.descriptionContainer}>
          <p className={styles.rightDescription}>{rightColumnDescription}</p>
        </div>
      </div>
    </NewStandardSection>
  );
};
