import React from 'react';

import featuredImage from '@images/java-development/featured-image.png';

import * as styles from '@pages/services/java-development/java-development.module.scss';

export const javaDevelopmentSEOProps = {
  seoTitle: 'Java Development Services Company - CodiLime',
  seoDescription:
    "Want to build a project in Java? Choose an experienced strategic partner. We've been developing software for tech-driven businesses since 2013.",
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      <span className={styles.headerTitleBlueSpan}>Java</span> software <br />
      development <br />
      services
    </>
  ),
  titleBorderWidth: styles.borderWidth,
  blueSection: true,
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Java development services - our approach',
  id: 'our-approach',
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications about software development',
    subtitle: (
      <>
        Software development is our area of expertise. If you want to know more about how we build robust, scalable
        solutions, check out our articles. You’ll learn more about <b>our business approach</b> and how we make software
        products successful.
      </>
    ),
    id: 'our-publications-about-java-development',
  },
  blueSection: true,
  blocks: [
    {
      text: 'Outsourcing software development in 2022: pros and cons',
      link: '/blog/outsourcing-software-development-pros-cons/',
    },
    {
      text: 'Three software development metrics for a startup environment',
      link: '/blog/three-software-development-metrics-for-a-startup-environment/',
    },
    {
      text: 'The Seven Stages of the SDLC',
      link: '/blog/the-stages-of-the-sdlc/',
    },
    {
      text: 'Security standards at CodiLime – how do we keep the data safe?',
      link: '/blog/security-standards-codilime-how-keep-data-safe/',
    },
    {
      text: 'SDLC methodologies—which one to choose for your project?',
      link: '/blog/sdlc-methodologies/',
    },
    {
      text: 'Top 10 frontend frameworks for software development',
      link: '/blog/top-frontend-frameworks-for-software-development/',
    },
  ],
};

export const blocksInRowsWithSomeActiveProps = {
  sectionProps: {
    title: 'Why is the Java programming language a good choice?',
    id: 'benefits',
    classNames: {
      titleStyles: styles.expandableSectionTitle,
    },
  },
  sections: [
    {
      title: 'Simplicity',
      description: (
        <>
          The stand-out feature of Java is its <b>ease of writing, compiling, and debugging.</b> It helps streamline the
          progress of Java development services, meaning development and maintenance are cost and time efficient.
        </>
      ),
    },
    {
      title: 'Flexibility',
      description: (
        <>
          Java programs can be executed on any operating system, platform, and architecture. That{' '}
          <b>platform independence</b> provides flexibility and portability, making Java software extremely versatile.
          Java developers are also flexible - often, they are comfortable switching to Kotlin or other languages and
          back again.
        </>
      ),
    },
    {
      title: 'Community',
      description: (
        <>
          The Java programming language is a solid, widely-used technology. Its <b>large community</b> ensures that
          there are very few problems that haven’t already been solved. The available knowledge and resources make Java
          developers more efficient and able to provide fast execution.
        </>
      ),
    },
    {
      title: 'Scalability',
      description: (
        <>
          Java is also an excellent choice for <b>projects that are expected to expand.</b> This language works for both
          smaller and larger applications, making business development a breeze.
        </>
      ),
    },
  ],
};

export const blocksWithStarsProps = {
  sectionProps: {
    title: 'The Java development company for you',
    subtitle: (
      <span>
        We’ve been operating as a software development company since 2011. Over many years of working on internal and
        external projects, we gained the know-how necessary to become a <b>Java development company of choice</b> for
        our clients. We know our way around Java and can help you <b>boost your software-based business!</b>
      </span>
    ),
    id: 'gray-blocks',
  },
  blocksData: [
    'Delivering fully-fledged Java development services in line with your requirements.',
    'Developing scalable software with real business value.',
    'Providing expert knowledge in data processing management.',
    'Supplying proficiency in web application resources and data engineering.',
    'Building effective document workflows.',
    'Using JVM as a platform for development in various languages.',
    'Creating human-readable reports based on large amounts of data.',
  ],
};
