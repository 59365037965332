import React from 'react';

import backgroundImage from './images/mountains.svg';

export const whyOutsourceJavaData = {
  title: 'Why should you outsource Java Development?',
  descriptionBelowTitle:
    'Java’s popularity and wide range of applications come with a vast amount of frameworks. This makes Java and other JVM programming languages great tools for developing projects with significant business value. The Java ecosystem is successfully used for:',
  javaUses: ['web application development', 'data engineering', 'data management', 'document workflow software'],
  rightColumnDescription: (
    <>
      The <b>Java Virtual Machine environment</b> can extend your software development possibilities by being a platform
      for applications written not only in Java but also in other programming languages, such as <b>Scala</b> or{' '}
      <b>Kotlin</b>.<br /> It allows for developing software faster, with various languages suitable for{' '}
      <b>particular problems and business domains.</b>
    </>
  ),
  backgroundImage: backgroundImage,
  backgroundImageAlt: 'outsource Java development',
};
