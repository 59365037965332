import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  BlocksChangingBackgroundColorOnHover,
  BlocksWithStars,
  NewClientsTestimonials,
  BlocksRedirectingToResources,
  BlocksInRowsWithSomeActive,
} from '@commons/index';

import {
  blocksRedirectingToResourcesProps,
  blocksChangingBackgroundColorOnHoverProps,
  javaDevelopmentSEOProps,
  headerProps,
  blocksInRowsWithSomeActiveProps,
  blocksWithStarsProps,
} from '@pages-impl/java-development/payload';
import { UnderHeaderSection, WhyOutsourceJava } from '@pages-impl/java-development';

export default function JavaDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Start your Java development journey with us"
      {...props}
    >
      <SEO {...javaDevelopmentSEOProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <UnderHeaderSection />
      <BlocksWithStars {...blocksWithStarsProps} />
      <WhyOutsourceJava />
      <BlocksInRowsWithSomeActive {...blocksInRowsWithSomeActiveProps} />
      <NewClientsTestimonials />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
    </Layout>
  );
}
