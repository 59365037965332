// extracted by mini-css-extract-plugin
export var backgroundImage = "why-outsource-java-module--background-image--5a00f";
export var description = "why-outsource-java-module--description--6bcc4";
export var descriptionContainer = "why-outsource-java-module--description-container--6cdae";
export var innerWrapper = "why-outsource-java-module--inner-wrapper--594fb";
export var leftColumn = "why-outsource-java-module--left-column--cf928";
export var outerWrapper = "why-outsource-java-module--outer-wrapper--74a42";
export var rightColumn = "why-outsource-java-module--right-column--454ca";
export var rightDescription = "why-outsource-java-module--right-description--cd42c";
export var title = "why-outsource-java-module--title--8df11";
export var titleAndDescriptionContainer = "why-outsource-java-module--title-and-description-container--0fe5b";
export var usagesContainer = "why-outsource-java-module--usages-container--8bc13";