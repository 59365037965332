import React from 'react';

import { underHeaderProps } from './payload';
import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import * as styles from './under-header.module.scss';

export const UnderHeaderSection = () => {
  const UnderLineTextBlock = ({ title }) => {
    return (
      <div className={styles.underLineTextContainer}>
        <span className={styles.boldText}>{title}</span>
        <div className={styles.underLine} />
      </div>
    );
  };

  return (
    <NewStandardSection {...underHeaderProps}>
      <div className={styles.container}>
        <span className={styles.subTitle}>
          Want to develop a software product based on mature, reliable technology? Look no further - Java development
          could be a good choice for you.
        </span>
        <span className={styles.subTitle}>
          This versatile and widely-used programming language is a great tool for:
        </span>

        <div className={styles.underLineTextsContainer}>
          <UnderLineTextBlock title="web development" />
          <UnderLineTextBlock title="cloud development" />
          <UnderLineTextBlock title="SaaS application development" />
        </div>
      </div>
    </NewStandardSection>
  );
};
